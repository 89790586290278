// @flow
import * as React from "react";
import { FormatEntry } from "../../components/format-entry/FormatEntry";
import { PageTemplate } from "./page-template/PageTemplate";

export function NotFound(): React.Node {
  return (
    <PageTemplate
      iconName="globe"
      title={<FormatEntry i18nEntry="page.notFound.title" />}
    />
  );
}
