// @flow
import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { LoginPage } from "../login-page/LoginPage";
import { TopBar } from "../components/top-bar/TopBar";
import { NotFound } from "./pages/NotFound";
import { appsManifest } from "./manifest";
import { InactiveUserError } from "./pages/InactiveUserError";
import { authWsClient } from "../ws-client/authWsClient";
import { redirectToLoginPage } from "../utils/redirectToLoginPage";
import { createRefreshAllSimpleUsersAction } from "../redux/model/simpleUsers";
import { useDispatch } from "react-redux";
import { initialStateWsClient } from "../ws-client/initialStateWsClient";
import { createUpdateLoggedUserAction } from "../redux/model/loggedUser";
import { UserNotAllowedToConnectError } from "./pages/UserNotAllowedToConnectError";
import { createUpdateAppEnvAction } from "../redux/model/appEnv";
import { ChangeRequestBanner } from "../components/change-request-banner/ChangeRequestBanner";
import { ScheduleProvider } from "../components/schedule-provider/ScheduleProvider";
import { AnnouncementWrapper } from "./AnnouncementWrapper";
import { createUpdateCalendarEntryDeadlinesAction } from "../redux/model/calendarEntryDeadlines";

type AppPropsType = {||};

export function App(props: AppPropsType): React.Node {
  const dispatch = useDispatch();
  const [loggedUserStatus, setLoggedUserStatus] = React.useState<
    "FETCHING" | "ERROR" | "RESPITE_STOP_CANNOT_CONNECT" | "INACTIVE" | "ACTIVE"
  >("FETCHING");
  const { isDarkMode, setIsDarkMode } = useTheme();

  const handleInitialState = React.useCallback(
    (initialState: InitialStateType) => {
      const { loggedUser, simpleUsers, calendarEntryDeadlines, appEnv } =
        initialState;
      dispatch(createRefreshAllSimpleUsersAction(simpleUsers));
      dispatch(createUpdateLoggedUserAction(loggedUser));
      dispatch(createUpdateAppEnvAction(appEnv));
      dispatch(
        createUpdateCalendarEntryDeadlinesAction(calendarEntryDeadlines)
      );
      if (!loggedUser.active) {
        setLoggedUserStatus("INACTIVE");
      } else if (
        loggedUser.role === "resident" &&
        loggedUser.typeOfCare.type === "respite-stop" &&
        !loggedUser.typeOfCare.canConnectToSystem
      ) {
        setLoggedUserStatus("RESPITE_STOP_CANNOT_CONNECT");
      } else {
        setLoggedUserStatus("ACTIVE");
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    initialStateWsClient
      .getInitialState()
      .then(handleInitialState)
      .catch(() => {
        setLoggedUserStatus("ERROR");
      });
  }, [handleInitialState]);

  function logOut() {
    authWsClient.logOut().then(() => {
      redirectToLoginPage({ logOutSource: "userDisconnected" });
    });
  }

  if (loggedUserStatus === "FETCHING") {
    return null;
  } else if (loggedUserStatus === "ERROR") {
    return (
      <LoginPage
        isDarkMode={isDarkMode}
        handleInitialState={handleInitialState}
      />
    );
  } else if (loggedUserStatus === "RESPITE_STOP_CANNOT_CONNECT") {
    return <UserNotAllowedToConnectError logOut={logOut} />;
  } else if (loggedUserStatus === "INACTIVE") {
    return <InactiveUserError logOut={logOut} />;
  }
  return (
    <AnnouncementWrapper>
      <ScheduleProvider>
        <div className="app-root">
          <TopBar
            logOut={logOut}
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
          />
          <div>
            <ChangeRequestBanner />
            <React.Suspense fallback={null}>
              <Switch>
                {appsManifest.map((pageDescription) => {
                  const { path, Component } = pageDescription;
                  return (
                    <Route key={path} path={path}>
                      <Component />
                    </Route>
                  );
                })}
                {appsManifest[0] && (
                  <Redirect from="/" to={appsManifest[0].path} exact />
                )}
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </ScheduleProvider>
    </AnnouncementWrapper>
  );
}

function useTheme() {
  const [isDarkMode, setIsDarkMode] = React.useState<boolean>(
    localStorage.getItem("isDarkMode") === "true"
  );

  React.useEffect(() => {
    localStorage.setItem("isDarkMode", String(isDarkMode));
    if (document.body) {
      if (isDarkMode) {
        document.body.classList.add("bp3-dark");
      } else {
        document.body.classList.remove("bp3-dark");
      }
    }
  }, [isDarkMode]);

  return { isDarkMode, setIsDarkMode };
}
