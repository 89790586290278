// @flow

type Opts = {|
  logOutSource?: "userDisconnected" | "disconnectedFrom401",
|};

export function redirectToLoginPage(opts?: Opts) {
  const urlSearchParams = new URLSearchParams();
  if (opts && opts.logOutSource) {
    urlSearchParams.set("logOutSource", opts.logOutSource);
  }
  const search = urlSearchParams.toString();
  // This will trigger a browser refresh, erasing any local state (incl. Redux store)
  window.location.href = `/${search ? `?${search}` : ""}`;
}

export function isRedirectedFrom401(searchParams: URLSearchParams): boolean {
  return searchParams.get("logOutSource") === "disconnectedFrom401";
}
