// @flow
import type {
  ActionsType,
  AppEnvStateType,
  UpdateAppEnvActionType,
} from "../types";

export function appEnvReducer(
  state: AppEnvStateType = null,
  action: ActionsType
): AppEnvStateType {
  if (action.type === "update-app-env") {
    const { appEnv } = action.payload;
    return appEnv;
  }
  return state;
}

export function createUpdateAppEnvAction(
  appEnv: AppEnv
): UpdateAppEnvActionType {
  return {
    type: "update-app-env",
    payload: { appEnv },
  };
}
