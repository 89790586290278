// @flow
import * as React from "react";
import clsx from "clsx";
import { Callout, Intent } from "@blueprintjs/core";
import { FormatEntry } from "../format-entry/FormatEntry";
import type { I18nEntryType } from "../../utils/i18n";
import "./Form.css";

export type FormValues = { [string]: any };
export type FormErrors = { [string]: React.Node };

export type ValidationRulesType = {
  [fieldName: string]: (
    fieldValue: any,
    allValues: FormValues
  ) => ?I18nEntryType | ?$ReadOnlyArray<I18nEntryType>,
};

export type FormRemoteErrors = {|
  // We need to also allow [fieldName: string] entries
  global: $ReadOnlyArray<I18nEntryType>,
|};

type FormPropsType = {|
  id?: string,
  className?: string,
  noStyle?: boolean,
  initialValues?: FormValues,
  validationRules?: ValidationRulesType,
  handleServerValidationError?: (ValidationErrorType) => ?I18nEntryType,
  onSubmit: (FormValues) => Promise<FormRemoteErrors | void>,
  children: ({|
    values: FormValues,
    errors: FormErrors,
    getOnChange: (fieldName: string) => (any) => void,
    getIntent: (fieldName: string) => BlueprintIntentType | void,
  |}) => React.Node,
  disableAutoComplete?: boolean,
|};

export function Form(props: FormPropsType): React.Node {
  const {
    id,
    className,
    noStyle,
    initialValues,
    validationRules,
    handleServerValidationError,
    onSubmit,
    children,
    disableAutoComplete,
  } = props;
  const [didAttemptSubmit, setDidAttemptSubmit] = React.useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = React.useState<
    $ReadOnlyArray<I18nEntryType>
  >([]);
  const [{ values, errors }, getOnChange] = useFormValues(
    initialValues,
    validationRules
  );
  const resolvedErrors = didAttemptSubmit ? errors : {};
  const getIntent = (fieldName) =>
    resolvedErrors[fieldName] ? Intent.DANGER : undefined;
  return (
    <form
      id={id}
      className={clsx("form", className, {
        "form--no-style": noStyle,
      })}
      autoComplete={disableAutoComplete ? "off" : undefined}
      onSubmit={(e) => {
        e.preventDefault();
        setDidAttemptSubmit(true);
        if (Object.keys(errors).length > 0) {
          setGlobalErrorMessage(["error.formContainsErrors"]);
          return;
        }
        setGlobalErrorMessage([]);
        onSubmit(values)
          .then((remoteErrors) => {
            if (remoteErrors) {
              setGlobalErrorMessage(remoteErrors.global);
            } else {
              setGlobalErrorMessage([]);
            }
          })
          .catch((err) => {
            const { response } = err;
            if (response) {
              const { status, data } = response;
              if (status === 400) {
                const defaultI18nEntry = {
                  key: "identity",
                  parameters: { message: JSON.stringify(data) },
                };
                const i18nEntry = handleServerValidationError?.(data);
                setGlobalErrorMessage([i18nEntry || defaultI18nEntry]);
                return;
              }
            }
            setGlobalErrorMessage(["error.unexpectedError"]);
          })
          .finally(() => {
            setDidAttemptSubmit(false);
          });
      }}
    >
      {globalErrorMessage.length > 0 && (
        <Callout intent={Intent.DANGER} className="marginBottom10px">
          {globalErrorMessage.map((i18nEntry, i) => {
            return <FormatEntry key={i} i18nEntry={i18nEntry} />;
          })}
        </Callout>
      )}
      {children({
        values,
        errors: resolvedErrors,
        getOnChange,
        getIntent,
      })}
    </form>
  );
}

function useFormValues(
  initialValues: ?FormValues,
  validationRules: ?ValidationRulesType
) {
  function applyValidationRules(vals) {
    const errors = {};
    const validationRulesDef = validationRules || {};
    Object.keys(validationRulesDef).forEach((fieldName) => {
      const result = validationRulesDef[fieldName](vals[fieldName], vals);
      if (result) {
        const resultArray: $ReadOnlyArray<I18nEntryType> = Array.isArray(result)
          ? result
          : [result];
        if (resultArray.length > 0) {
          errors[fieldName] = resultArray.map((i18nEntry, i) => {
            return (
              <p key={i}>
                <FormatEntry i18nEntry={i18nEntry} />
              </p>
            );
          });
        }
      }
    });
    return errors;
  }

  const initialVals = initialValues || {};

  const [valuesAndErrors, dispatch] = React.useReducer(
    (
      state: { values: { [string]: any }, errors: { [string]: React.Node } },
      action: {|
        type: "update",
        fieldName: string,
        value: any,
      |}
    ) => {
      let newValues = state.values;
      if (action.type === "update") {
        const { fieldName, value } = action;
        newValues = { ...state.values, [fieldName]: value };
      }
      return { values: newValues, errors: applyValidationRules(newValues) };
    },
    { values: initialVals, errors: applyValidationRules(initialVals) }
  );

  function getOnChange(fieldName) {
    return (value) => {
      dispatch({
        type: "update",
        fieldName,
        value,
      });
    };
  }

  return [valuesAndErrors, getOnChange];
}
