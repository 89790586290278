// @flow
import * as React from "react";
import { FormatEntry } from "../../components/format-entry/FormatEntry";
import { PageTemplate } from "./page-template/PageTemplate";

export function UnexpectedError(props: {| error: Error |}): React.Node {
  const { error } = props;
  return (
    <PageTemplate
      iconName="error"
      title={<FormatEntry i18nEntry="page.unexpectedError.title" />}
    >
      <p>
        {error.message}
        <br />
        <code>{error.stack}</code>
      </p>
    </PageTemplate>
  );
}
