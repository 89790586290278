// @flow
import { axiosRedirectOn401 } from "./_axiosInstances";

export const changeRequestWsClient = {
  listChangeRequests(): Promise<$ReadOnlyArray<ChangeRequestComplete>> {
    return axiosRedirectOn401
      .get(`/ws/change-requests/complete-requests`)
      .then((res) => {
        return res.data;
      });
  },

  cancelChangeRequest(changeRequestId: string): Promise<CalendarEventLog> {
    return axiosRedirectOn401
      .post(`/ws/change-requests/${changeRequestId}/cancel-request`)
      .then((res) => res.data);
  },

  rejectChangeRequest(
    changeRequestId: string,
    changeRequestVersion: number
  ): Promise<CalendarEventLog> {
    return axiosRedirectOn401
      .post(
        `/ws/change-requests/${changeRequestId}/reject-request?version=${changeRequestVersion}`
      )
      .then((res) => res.data);
  },

  acceptChangeRequest(
    changeRequestId: string,
    changeRequestVersion: number
  ): Promise<CalendarEventLog> {
    return axiosRedirectOn401
      .post(
        `/ws/change-requests/${changeRequestId}/accept-request?version=${changeRequestVersion}`
      )
      .then((res) => res.data);
  },
};
