// @flow
import { Intent } from "@blueprintjs/core";
import camelCase from "lodash.camelcase";
import type { I18nEntryType } from "./i18n";

/* --- ops --- */

export function mapUserToSimpleUser(user: User): SimpleUser {
  const { comments, emailAddresses, active, ...simpleUser } = user;
  return simpleUser;
}

/* --- formatting --- */

export function formatUser(
  user: User | SimpleUser,
  firstWord: "firstName" | "lastName" = "firstName",
  format: "normal" | "short" = "normal"
): string {
  const { firstName, lastName } = user;
  if (format === "short") {
    return firstWord === "lastName"
      ? `${lastName} ${shorten(firstName)}`
      : `${firstName} ${shorten(lastName)}`;
  } else {
    return firstWord === "lastName"
      ? `${lastName} ${firstName}`
      : `${firstName} ${lastName}`;
  }
}

function shorten(str: string): string {
  return str.split(" ").reduce((acc, substr) => {
    const trimmed = substr.trim();
    if (trimmed) {
      return acc + trimmed[0] + ".";
    }
    return acc;
  }, "");
}

/* --- role --- */

export const userRoles: $ReadOnlyArray<UserRole> = [
  "superAdmin",
  "admin",
  "staff",
  "resident",
];

export const userRoleToBluePrintIntent: { [UserRole]: string } = {
  superAdmin: Intent.DANGER,
  admin: Intent.WARNING,
  // No intent for those, otherwise the screen will look like a Christmas tree...
  staff: Intent.NONE,
  resident: Intent.NONE,
};

export function isSuperAdminUser(user: User | SimpleUser): boolean %checks {
  return user.role === "superAdmin";
}

export function isAdminUser(user: User | SimpleUser): boolean %checks {
  return user.role === "admin";
}

export function isStaffUser(user: User | SimpleUser): boolean %checks {
  return user.role === "staff";
}

export function isResidentUser(user: User | SimpleUser): boolean %checks {
  return user.role === "resident";
}

export function getUserRoleI18nEntry(role: UserRole): I18nEntryType {
  return `model.user.role.possibleValues.${role}`;
}

/* --- type of care --- */

export function getUserTypeOfCareI18nEntry(
  typeOfCare: "housing" | "respite-stop"
): I18nEntryType {
  return `model.user.typeOfCare.possibleValues.${camelCase(typeOfCare)}`;
}

export function isHousingResident(user: User | SimpleUser): boolean %checks {
  return isResidentUser(user) && user.typeOfCare.type === "housing";
}

export function isRespiteStopResident(
  user: User | SimpleUser
): boolean %checks {
  return isResidentUser(user) && user.typeOfCare.type === "respite-stop";
}

export function hasUserAccessToOtherUser(
  user: User | SimpleUser,
  targetUser: User | SimpleUser
): boolean {
  return (
    user.role === "staff" ||
    user.role === "admin" ||
    user.role === "superAdmin" ||
    user.username === targetUser.username
  );
}
