// @flow
import { useSelector } from "react-redux";
import type { StateType } from "../redux/types";

export function useAppEnv(): AppEnv {
  const appEnv = useSelector<StateType, AppEnv | null>((state) => state.appEnv);
  if (!appEnv) {
    throw new Error(
      "useAppEnv may be called only when initial state has been resolved"
    );
  }
  return appEnv;
}
