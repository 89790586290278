// @flow
import React from "react";
import { useScheduleManager } from "./useScheduleManager";

type UseChangeRequestsSubscriptionReturnType = {|
  changeRequests: null | $ReadOnlyArray<ChangeRequestComplete>,
|};

export function useChangeRequestsSubscription(): UseChangeRequestsSubscriptionReturnType {
  const scheduleManager = useScheduleManager();

  // useReducer is chosen over setState for convenience
  const [, forceRender] = React.useReducer((counter) => counter + 1, 0);

  // Using ref instead of state because we want updates to be synchronous
  const changeRequestsRef = React.useRef(scheduleManager.changeRequests);

  React.useLayoutEffect(() => {
    return scheduleManager.subscribeForChangeRequests({
      listener(changeRequests) {
        changeRequestsRef.current = changeRequests;
        forceRender();
      },
    });
  }, [scheduleManager]);

  return {
    changeRequests: changeRequestsRef.current,
  };
}
