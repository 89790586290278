// @flow

export const MANAGE_SYSTEM_PARAMETERS_ROUTE = "/parametres";

export const MANAGE_PARAMETERS_TAB_ROUTE = `${MANAGE_SYSTEM_PARAMETERS_ROUTE}/:tabId`;
export const VIEW_HOLIDAYS_ROUTE = `${MANAGE_SYSTEM_PARAMETERS_ROUTE}/conges`;
export const EDIT_CALENDAR_ENTRY_DEADLINES_ROUTE = `${MANAGE_SYSTEM_PARAMETERS_ROUTE}/delais-calendrier/modifier`;

export const CREATE_HOLIDAY_ROUTE = `${VIEW_HOLIDAYS_ROUTE}/ajouter`;
export const EDIT_HOLIDAY_ROUTE = `${VIEW_HOLIDAYS_ROUTE}/:holidayId/modifier`;

export function getManageSystemParametersUrl(): string {
  return MANAGE_SYSTEM_PARAMETERS_ROUTE;
}

export function getManageParametersTabUrl(tabId: string): string {
  return MANAGE_PARAMETERS_TAB_ROUTE.replace(":tabId", tabId);
}

export function getViewHolidaysUrl(): string {
  return VIEW_HOLIDAYS_ROUTE;
}

export function getCreateHolidayUrl(): string {
  return CREATE_HOLIDAY_ROUTE;
}

export function getEditHolidayUrl(holidayId: string): string {
  return EDIT_HOLIDAY_ROUTE.replace(":holidayId", holidayId);
}

export function getEditCalendarEntryDeadlineUrl(): string {
  return EDIT_CALENDAR_ENTRY_DEADLINES_ROUTE;
}
