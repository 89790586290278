// @flow
import * as React from "react";
import "./PageTemplate.css";
import { Icon } from "@blueprintjs/core";
import clsx from "clsx";

type PageTemplatePropsType = {|
  iconName: string,
  title: React.Node,
  fullScreen?: boolean,
  children?: React.Node,
|};

export function PageTemplate(props: PageTemplatePropsType): React.Node {
  const { iconName, title, fullScreen, children } = props;
  const className = clsx("page-message-block", {
    marginTop10px: !fullScreen,
  });
  return (
    <>
      {fullScreen && <div /* takes up the topbar placeholder in the grid */ />}
      <div className={className}>
        {iconName && (
          <Icon
            icon={iconName}
            iconSize={60}
            className="marginBottom10px"
            color="#a1a1a1"
          />
        )}
        <h4 className="bp3-heading">{title}</h4>
        <div className="align-center">{children}</div>
      </div>
    </>
  );
}

PageTemplate.displayName = "PageTemplate";
