// @flow

export const MANAGE_USERS_ROUTE = "/utilisateurs";
export const CREATE_USER_ROUTE = "/utilisateurs/ajouter";
export const EDIT_USER_ROUTE = "/utilisateurs/:username";

export function getManageUsersUrl(): string {
  return MANAGE_USERS_ROUTE;
}

export function getCreateUserUrl(): string {
  return CREATE_USER_ROUTE;
}

export function getEditUserUrl(username: string): string {
  return EDIT_USER_ROUTE.replace(":username", username);
}
