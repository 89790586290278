// @flow
import * as React from "react";
import { FormattedMessage } from "react-intl";
import type { I18nEntryType } from "../../utils/i18n";
import { normalizeI18nEntry } from "../../utils/i18n";
import { useI18n } from "./useI18n";

type FormatEntryPropsType = {|
  i18nEntry: I18nEntryType,
|};

export function FormatEntry(props: FormatEntryPropsType): React.Node {
  const { i18nEntry } = props;
  const normalizedI18nEntry = normalizeI18nEntry(i18nEntry);
  const { formatEntry } = useI18n();
  const { key, parameters } = normalizedI18nEntry;
  if (key.endsWith("_dangerouslySetInnerHtml")) {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: formatEntry(normalizedI18nEntry) }}
      />
    );
  } else {
    return <FormattedMessage id={key} values={parameters} />;
  }
}
