// @flow
import * as React from "react";
import { AppErrorBoundary } from "./AppErrorBoundary";
import { MANAGE_USERS_ROUTE } from "./manage-users/routes";
import { MANAGE_SYSTEM_PARAMETERS_ROUTE } from "./manage-system-parameters/routes";
import { EDIT_PROFILE_ROUTE } from "./edit-profile/routes";
import { EditProfile } from "./edit-profile/EditProfile";
import { MANAGE_SCHEDULE_ROUTE } from "./manage-schedule/routes";
import { MANAGE_CHANGE_REQUESTS_ROUTE } from "./manage-change-requests/routes";

const rawManifestObject = {
  [MANAGE_SCHEDULE_ROUTE]: React.lazy(() =>
    import("./manage-schedule/ManageSchedule")
  ),
  [MANAGE_CHANGE_REQUESTS_ROUTE]: React.lazy(() =>
    import("./manage-change-requests/ManageChangeRequests")
  ),
  [MANAGE_USERS_ROUTE]: React.lazy(() => import("./manage-users/ManageUsers")),
  [MANAGE_SYSTEM_PARAMETERS_ROUTE]: React.lazy(() =>
    import("./manage-system-parameters/ManageSystemParameters")
  ),
  [EDIT_PROFILE_ROUTE]: EditProfile,
};

function withPageErrorBoundary(Component: React.AbstractComponent<{||}>) {
  function WithPageErrorBoundary(props: {||}) {
    return (
      <AppErrorBoundary>
        <Component {...props} />
      </AppErrorBoundary>
    );
  }
  WithPageErrorBoundary.displayName = `WithPageErrorBoundary(${
    Component.displayName || Component.name || "Component"
  })`;
  return WithPageErrorBoundary;
}

export const appsManifest: $ReadOnlyArray<{|
  path: string,
  Component: React.AbstractComponent<{||}>,
|}> = Object.keys(rawManifestObject).map((path) => {
  return {
    path,
    Component: withPageErrorBoundary(rawManifestObject[path]),
  };
});
