// @flow
import * as React from "react";
import "./ChangeRequestBanner.css";
import { Link, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { FormatEntry } from "../format-entry/FormatEntry";
import { getManageChangeRequestsUrl } from "../../apps/manage-change-requests/routes";
import { useChangeRequestsSubscription } from "../schedule-provider/hooks/useChangeRequestsSubscription";
import { useScheduleManager } from "../schedule-provider/hooks/useScheduleManager";

type ChangeRequestBannerProps = {||};

export function ChangeRequestBanner(
  props: ChangeRequestBannerProps
): React.Node {
  const scheduleManager = useScheduleManager();
  const match = useRouteMatch(getManageChangeRequestsUrl());
  if (!scheduleManager.isLoggedUserAllowedToManageChangeRequests || match) {
    return null;
  } else {
    return <ChangeRequestBannerInternal />;
  }
}

ChangeRequestBanner.displayName = "ChangeRequestBanner";

function ChangeRequestBannerInternal() {
  const { changeRequests } = useChangeRequestsSubscription();
  const scheduleManager = useScheduleManager();

  React.useEffect(() => {
    if (changeRequests === null) {
      scheduleManager.refreshChangeRequestsIfAllowed();
    }
  }, [changeRequests, scheduleManager]);

  const className = clsx("change-request-banner", {
    "change-request-banner--warning":
      !!changeRequests && changeRequests.length > 0,
  });
  const txtNode = !!changeRequests && (
    <FormatEntry
      i18nEntry={{
        key: "component.changeRequestBanner.pendingChangeRequests",
        parameters: { nbChangeRequests: changeRequests.length },
      }}
    />
  );
  return (
    <div className={className}>
      {changeRequests && changeRequests.length === 0 && txtNode}
      {changeRequests && changeRequests.length > 0 && (
        <Link to={getManageChangeRequestsUrl()}>{txtNode}</Link>
      )}
    </div>
  );
}

ChangeRequestBannerInternal.displayName = "ChangeRequestBannerInternal";
