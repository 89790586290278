// @flow
import * as React from "react";
import { ScheduleManagerContext } from "./schedule-manager/schedule-manager-context";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import { createScheduleManager } from "./schedule-manager/createScheduleManager";
import { Intent, Position, Toaster } from "@blueprintjs/core";
import { useI18n } from "../format-entry/useI18n";
import type { I18nEntryType } from "../../utils/i18n";

type ScheduleProviderProps = {|
  children: React.Node,
|};

export function ScheduleProvider(props: ScheduleProviderProps): React.Node {
  const { children } = props;
  const loggedUser = useLoggedUser();
  const { formatEntry } = useI18n();
  const [scheduleManager] = React.useState(() => {
    return createScheduleManager(loggedUser, {
      onNewChangeRequest() {
        onNewChangeRequest(formatEntry);
      },
    });
  });

  return (
    <ScheduleManagerContext.Provider value={scheduleManager}>
      {children}
    </ScheduleManagerContext.Provider>
  );
}

ScheduleProvider.displayName = "ScheduleProvider";

const ChangeRequestToaster = Toaster.create({
  position: Position.TOP,
});

function onNewChangeRequest(formatEntry: (I18nEntryType) => string) {
  ChangeRequestToaster.show({
    message: formatEntry("app.manageSchedule.notification.newChangeRequest"),
    intent: Intent.WARNING,
    timeout: 5_000,
  });
}
