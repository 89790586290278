// @flow
import { axiosRedirectOn401 } from "./_axiosInstances";

export const calendarEntryWSClient = {
  createCalendarEntry(
    username: string,
    calendarEntryCreateOrUpdate: Object
  ): Promise<CalendarEventLog> {
    return axiosRedirectOn401
      .post(`/ws/calendarEntry/${username}`, calendarEntryCreateOrUpdate)
      .then((res) => res.data);
  },

  searchCalendarEntries(
    lowerBoundLocalDateTime: string,
    upperBoundLocalDateTime: string,
    usernames: $ReadOnlyArray<string>
  ): Promise<$ReadOnlyArray<CalendarEntry>> {
    const body = {
      lowerBoundLocalDateTime,
      upperBoundLocalDateTime,
      usernames,
    };
    return axiosRedirectOn401
      .post(`/ws/calendarEntry/search`, body)
      .then((res) => {
        return res.data;
      });
  },

  updateCalendarEntry(
    calendarEntryId: string,
    calendarEntryCreateOrUpdate: Object
  ): Promise<CalendarEventLog> {
    return axiosRedirectOn401
      .put(`/ws/calendarEntry/${calendarEntryId}`, calendarEntryCreateOrUpdate)
      .then((res) => res.data);
  },

  deleteCalendarEntry(
    calendarEntryId: string
  ): Promise<CalendarEventLog | void> {
    return axiosRedirectOn401
      .delete(`/ws/calendarEntry/${calendarEntryId}`)
      .then((res) => res.data);
  },

  updateCalendarEntryDeadlines(
    newDeadlines: CalendarEntryDeadlines
  ): Promise<CalendarEntryDeadlines> {
    return axiosRedirectOn401
      .post(`/ws/calendarEntry/update-deadlines`, newDeadlines)
      .then((res) => res.data);
  },
};
