// @flow
export type NormalizedI18nEntryType = {
  key: string,
  parameters: { [key: string]: string | number },
};

export type I18nEntryType = string | NormalizedI18nEntryType;

export function normalizeI18nEntry(
  i18nEntry: I18nEntryType
): NormalizedI18nEntryType {
  if (typeof i18nEntry === "string") {
    return { key: i18nEntry, parameters: {} };
  } else {
    return i18nEntry;
  }
}

export function i18nIdentity(message: string): I18nEntryType {
  return {
    key: "identity",
    parameters: { message },
  };
}
