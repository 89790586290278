// @flow
import { calendarEntryWSClient } from "../../../ws-client/calendarEntryWsClient";
import type {
  CalendarEntryCreateOrUpdate,
  CalendarEntrySubscriptionInformation,
  ChangeRequestSubscriptionInformation,
  MealEntryCreate,
  MealEntrySubscriptionInformation,
  ScheduleManager,
} from "../calendar-entry-provider-types";
import {
  isAdminUser,
  isResidentUser,
  isSuperAdminUser,
} from "../../../utils/user";
import { changeRequestWsClient } from "../../../ws-client/changeRequestWsClient";
import { mealEntryWSClient } from "../../../ws-client/mealEntryWsClient";

type ScheduleManagerState = {|
  calendarEntriesByUsername: {
    // the `void` is a safeguard to remind us that no calendarEntries might have been fetched for the user
    [username: string]: Array<CalendarEntry> | void,
  },
  calendarEntriesSubscriptionsByUsername: {
    // the `void` is a safeguard to remind us that no subscription might have been made for the user
    [username: string]: Array<CalendarEntrySubscriptionInformation> | void,
  },
  currentCalendarEntriesSearchParameters: {|
    lowerBoundLocalDateTime: LocalDateTimeString,
    upperBoundLocalDateTime: LocalDateTimeString,
    usernames: $ReadOnlyArray<string>,
  |} | null,

  mealEntriesByUsername: {
    // the `void` is a safeguard to remind us that no calendarEntries might have been fetched for the user
    [username: string]: Array<MealEntry> | void,
  },
  mealEntriesSubscriptionsByUsername: {
    // the `void` is a safeguard to remind us that no subscription might have been made for the user
    [username: string]: Array<MealEntrySubscriptionInformation> | void,
  },
  currentMealEntriesSearchParameters: {|
    lowerBoundDate: DateString,
    upperBoundDate: DateString,
    usernames: $ReadOnlyArray<string>,
  |} | null,

  changeRequests: null | Array<ChangeRequestComplete>,
  changeRequestsSubscriptions: Array<ChangeRequestSubscriptionInformation>,
|};

export function createScheduleManager(
  loggedUser: User,
  opts: {| onNewChangeRequest: () => void |}
): ScheduleManager {
  const state: ScheduleManagerState = {
    calendarEntriesByUsername: {},
    calendarEntriesSubscriptionsByUsername: {},
    currentCalendarEntriesSearchParameters: null,
    mealEntriesByUsername: {},
    mealEntriesSubscriptionsByUsername: {},
    currentMealEntriesSearchParameters: null,
    changeRequests: null,
    changeRequestsSubscriptions: [],
  };

  function getCalendarEntries(username: string): $ReadOnlyArray<CalendarEntry> {
    const calendarEntries = state.calendarEntriesByUsername[username];
    if (!calendarEntries) {
      const newCalendarEntries = [];
      state.calendarEntriesByUsername[username] = newCalendarEntries;
      return newCalendarEntries;
    } else {
      return calendarEntries;
    }
  }

  function dispatchCalendarEntryUpdate(username: string) {
    const calendarEntriesSubscriptions =
      state.calendarEntriesSubscriptionsByUsername[username];
    if (calendarEntriesSubscriptions) {
      for (const subscriptionInformation of calendarEntriesSubscriptions) {
        subscriptionInformation.listener(getCalendarEntries(username));
      }
    }
  }

  function broadcastCalendarEntryUpdate() {
    const usernames = Object.keys(state.calendarEntriesSubscriptionsByUsername);
    for (const username of usernames) {
      dispatchCalendarEntryUpdate(username);
    }
  }

  async function searchCalendarEntries(
    lowerBoundLocalDateTime: LocalDateTimeString,
    upperBoundLocalDateTime: LocalDateTimeString,
    usernames: $ReadOnlyArray<string>
  ): Promise<void> {
    const searchParametersState = {
      lowerBoundLocalDateTime,
      upperBoundLocalDateTime,
      usernames,
    };
    state.currentCalendarEntriesSearchParameters = searchParametersState;
    const [calendarEntries] = await Promise.all([
      calendarEntryWSClient.searchCalendarEntries(
        lowerBoundLocalDateTime,
        upperBoundLocalDateTime,
        usernames
      ),
      refreshChangeRequestsIfAllowed(),
    ]);

    if (
      state.currentCalendarEntriesSearchParameters !== searchParametersState
    ) {
      return;
    }
    const newCalendarEntriesByUsername = {};
    for (const calendarEntry of calendarEntries) {
      if (!newCalendarEntriesByUsername[calendarEntry.username]) {
        newCalendarEntriesByUsername[calendarEntry.username] = [calendarEntry];
      } else {
        newCalendarEntriesByUsername[calendarEntry.username].push(
          calendarEntry
        );
      }
    }
    state.calendarEntriesByUsername = newCalendarEntriesByUsername;
    broadcastCalendarEntryUpdate();
  }

  function subscribeForCalendarEntries(
    username: string,
    subscriptionInformation: CalendarEntrySubscriptionInformation
  ) {
    if (!state.calendarEntriesSubscriptionsByUsername[username]) {
      state.calendarEntriesSubscriptionsByUsername[username] = [
        subscriptionInformation,
      ];
    } else {
      state.calendarEntriesSubscriptionsByUsername[username].push(
        subscriptionInformation
      );
    }

    subscriptionInformation.listener(getCalendarEntries(username));

    return function unsubscribe() {
      if (state.calendarEntriesSubscriptionsByUsername[username]) {
        state.calendarEntriesSubscriptionsByUsername[username].splice(
          state.calendarEntriesSubscriptionsByUsername[username].indexOf(
            subscriptionInformation
          ),
          1
        );
      }
    };
  }

  function _replaceCalendarEntryInState(
    username: string,
    calendarEntry: CalendarEntry
  ) {
    const calendarEntries = getCalendarEntries(username);
    const index = calendarEntries.findIndex((cE) => cE.id === calendarEntry.id);
    if (index < 0) {
      _insertCalendarEntryInState(username, calendarEntry);
    } else {
      let newCalendarEntries = [
        ...calendarEntries.slice(0, index),
        calendarEntry,
        ...calendarEntries.slice(index + 1, calendarEntries.length),
      ];

      // Edge case: local cache is not consistent with the db after a change-request got accepted. We need to clean up
      const formerCalendarEntry = calendarEntries[index];
      if (
        formerCalendarEntry.status.code === "change-request-target" &&
        calendarEntry.status.code === "ok"
      ) {
        const { changeRequestId } = formerCalendarEntry.status;
        newCalendarEntries = newCalendarEntries.filter(
          (calendarEntry) =>
            calendarEntry.status.code !== "change-request-origin" ||
            calendarEntry.status.changeRequestId !== changeRequestId
        );
      }

      state.calendarEntriesByUsername[username] = newCalendarEntries;
    }
  }

  function _insertCalendarEntryInState(
    username: string,
    calendarEntry: CalendarEntry
  ) {
    // calendar entries must remain sorted
    const calendarEntries = getCalendarEntries(username);
    let index = 0;
    for (let i = 0; i < calendarEntries.length; i++) {
      const calendarEntryAtIndex = calendarEntries[i];
      if (
        calendarEntry.startLocalDateTime <
        calendarEntryAtIndex.startLocalDateTime
      ) {
        break;
      } else {
        index = i + 1;
      }
    }
    state.calendarEntriesByUsername[username] = [
      ...calendarEntries.slice(0, index),
      calendarEntry,
      ...calendarEntries.slice(index, calendarEntries.length),
    ];
  }

  async function _onNewChangeRequest() {
    opts.onNewChangeRequest();
    await refreshChangeRequestsIfAllowed();
  }

  async function createCalendarEntry(
    username: string,
    calendarEntryCreateOrUpdate: CalendarEntryCreateOrUpdate
  ) {
    const calendarEventLog = await calendarEntryWSClient.createCalendarEntry(
      username,
      calendarEntryCreateOrUpdate
    );
    const { event } = calendarEventLog;
    let calendarEntry;
    if (event.type === "create-calendar-entry") {
      calendarEntry = event.newValue;
    } else if (event.type === "create-calendar-entry-change-request") {
      await _onNewChangeRequest();
      if (event.target) {
        calendarEntry = event.target;
      }
    }

    if (!calendarEntry) {
      return;
    }

    _insertCalendarEntryInState(username, calendarEntry);
    dispatchCalendarEntryUpdate(username);
  }

  async function updateCalendarEntry(
    username: string,
    calendarEntryId: string,
    calendarEntryCreateOrUpdate: CalendarEntryCreateOrUpdate,
    optimistic: boolean
  ) {
    const _updateStateAndDispatchEvent = (
      updatedCalendarEntry: CalendarEntry,
      additionalEntry?: CalendarEntry
    ) => {
      _replaceCalendarEntryInState(username, updatedCalendarEntry);
      if (additionalEntry) {
        _insertCalendarEntryInState(username, additionalEntry);
      }
      dispatchCalendarEntryUpdate(username);
    };

    if (optimistic) {
      const calendarEntries = getCalendarEntries(username);
      const existingCalendarEntry = calendarEntries.find(
        (cE) => cE.id === calendarEntryId
      );
      _updateStateAndDispatchEvent({
        ...calendarEntryCreateOrUpdate,
        id: calendarEntryId,
        version: existingCalendarEntry?.version
          ? existingCalendarEntry.version + 1
          : 0,
        username,
        status: existingCalendarEntry?.status || { code: "ok", flexData: null },
      });
    }

    const calendarEventLog = await calendarEntryWSClient.updateCalendarEntry(
      calendarEntryId,
      calendarEntryCreateOrUpdate
    );
    const { event } = calendarEventLog;
    if (event.type === "update-calendar-entry") {
      _updateStateAndDispatchEvent(event.newValue);
      if (event.newValue.status.code === "change-request-target") {
        await refreshChangeRequestsIfAllowed();
      }
    } else if (event.type === "create-calendar-entry-change-request") {
      await _onNewChangeRequest();
      if (event.origin && event.target) {
        _updateStateAndDispatchEvent(event.origin, event.target);
      }
    }
  }

  async function deleteCalendarEntry(
    username: string,
    calendarEntryId: string
  ) {
    const calendarEventLog = await calendarEntryWSClient.deleteCalendarEntry(
      calendarEntryId
    );
    if (
      !calendarEventLog ||
      calendarEventLog.event.type === "delete-calendar-entry"
    ) {
      const calendarEntries = getCalendarEntries(username);
      state.calendarEntriesByUsername[username] = calendarEntries.filter(
        (cE) => {
          return cE.id !== calendarEntryId;
        }
      );
      dispatchCalendarEntryUpdate(username);
    } else if (
      calendarEventLog.event.type === "create-calendar-entry-change-request"
    ) {
      await _onNewChangeRequest();
      if (calendarEventLog.event.origin) {
        const { origin } = calendarEventLog.event;
        _replaceCalendarEntryInState(username, origin);
        dispatchCalendarEntryUpdate(username);
      }
    }
  }

  /* ------ meal entries ------- */

  function _insertMealEntryInState(username: string, mealEntry: MealEntry) {
    // meal entries must remain sorted
    const mealEntries = getMealEntries(username);
    let index = 0;
    for (let i = 0; i < mealEntries.length; i++) {
      const mealEntryAtIndex = mealEntries[i];
      if (mealEntry.date < mealEntryAtIndex.date) {
        break;
      } else {
        index = i + 1;
      }
    }
    state.mealEntriesByUsername[username] = [
      ...mealEntries.slice(0, index),
      mealEntry,
      ...mealEntries.slice(index, mealEntries.length),
    ];
  }

  function dispatchMealEntryUpdate(username: string) {
    const mealEntriesSubscriptions =
      state.mealEntriesSubscriptionsByUsername[username];
    if (mealEntriesSubscriptions) {
      for (const subscriptionInformation of mealEntriesSubscriptions) {
        subscriptionInformation.listener(getMealEntries(username));
      }
    }
  }

  function broadcastMealEntryUpdate() {
    const usernames = Object.keys(state.mealEntriesSubscriptionsByUsername);
    for (const username of usernames) {
      dispatchMealEntryUpdate(username);
    }
  }

  function subscribeForMealEntries(
    username: string,
    subscriptionInformation: MealEntrySubscriptionInformation
  ): () => void {
    if (!state.mealEntriesSubscriptionsByUsername[username]) {
      state.mealEntriesSubscriptionsByUsername[username] = [
        subscriptionInformation,
      ];
    } else {
      state.mealEntriesSubscriptionsByUsername[username].push(
        subscriptionInformation
      );
    }

    subscriptionInformation.listener(getMealEntries(username));

    return function unsubscribe() {
      if (state.mealEntriesSubscriptionsByUsername[username]) {
        state.mealEntriesSubscriptionsByUsername[username].splice(
          state.mealEntriesSubscriptionsByUsername[username].indexOf(
            subscriptionInformation
          ),
          1
        );
      }
    };
  }

  function getMealEntries(username: string): $ReadOnlyArray<MealEntry> {
    const mealEntries = state.mealEntriesByUsername[username];
    if (!mealEntries) {
      const newMealEntries = [];
      state.mealEntriesByUsername[username] = newMealEntries;
      return newMealEntries;
    } else {
      return mealEntries;
    }
  }

  async function searchMealEntries(
    lowerBoundDate: DateString,
    upperBoundDate: DateString,
    usernames: $ReadOnlyArray<string>
  ): Promise<void> {
    const searchParametersState = {
      lowerBoundDate,
      upperBoundDate,
      usernames,
    };
    state.currentMealEntriesSearchParameters = searchParametersState;
    const [mealEntries] = await Promise.all([
      mealEntryWSClient.searchMealEntries(
        lowerBoundDate,
        upperBoundDate,
        usernames
      ),
      refreshChangeRequestsIfAllowed(),
    ]);

    if (state.currentMealEntriesSearchParameters !== searchParametersState) {
      return;
    }
    const newMealEntriesByUsername = {};
    for (const mealEntry of mealEntries) {
      if (!newMealEntriesByUsername[mealEntry.username]) {
        newMealEntriesByUsername[mealEntry.username] = [mealEntry];
      } else {
        newMealEntriesByUsername[mealEntry.username].push(mealEntry);
      }
    }
    state.mealEntriesByUsername = newMealEntriesByUsername;
    broadcastMealEntryUpdate();
  }

  async function createMealEntry(
    username: string,
    mealEntryCreate: MealEntryCreate
  ): Promise<void> {
    const calendarEventLog = await mealEntryWSClient.createMealEntry(
      username,
      mealEntryCreate
    );
    const { event } = calendarEventLog;
    let mealEntry;
    if (event.type === "create-meal-entry") {
      mealEntry = event.newValue;
    } else if (event.type === "create-meal-entry-change-request") {
      await _onNewChangeRequest();
      if (event.entry) {
        mealEntry = event.entry;
      }
    }

    if (!mealEntry) {
      return;
    }

    _insertMealEntryInState(username, mealEntry);
    dispatchMealEntryUpdate(username);
  }

  async function deleteMealEntry(
    username: string,
    mealEntryId: string
  ): Promise<void> {
    const calendarEventLog = await mealEntryWSClient.deleteMealEntry(
      mealEntryId
    );
    if (
      !calendarEventLog ||
      calendarEventLog.event.type === "delete-meal-entry"
    ) {
      const mealEntries = getMealEntries(username);
      state.mealEntriesByUsername[username] = mealEntries.filter((cE) => {
        return cE.id !== mealEntryId;
      });
      dispatchMealEntryUpdate(username);
    }
  }

  /* ------ change requests ------- */
  const isLoggedUserAllowedToManageChangeRequests =
    isSuperAdminUser(loggedUser) || isAdminUser(loggedUser);
  const isLoggedUserAllowedToReadChangeRequests =
    isResidentUser(loggedUser) || isLoggedUserAllowedToManageChangeRequests;

  function subscribeForChangeRequests(
    subscriptionInformation: ChangeRequestSubscriptionInformation
  ) {
    state.changeRequestsSubscriptions.push(subscriptionInformation);
    return function unsubscribe() {
      state.changeRequestsSubscriptions.splice(
        state.changeRequestsSubscriptions.indexOf(subscriptionInformation),
        1
      );
    };
  }

  function dispatchChangeRequestsUpdate() {
    for (const subscriptionInformation of state.changeRequestsSubscriptions) {
      subscriptionInformation.listener(state.changeRequests);
    }
  }

  async function refreshChangeRequestsIfAllowed() {
    if (!isLoggedUserAllowedToReadChangeRequests) {
      return;
    }
    const newChangeRequests = await changeRequestWsClient.listChangeRequests();
    state.changeRequests = [...newChangeRequests];
    dispatchChangeRequestsUpdate();
  }

  function _removeCalendarEntriesForChangeRequest(
    username: string,
    origin: CalendarEntry | null,
    target: CalendarEntry | null
  ) {
    const calendarEntries = getCalendarEntries(username);
    state.calendarEntriesByUsername[username] = calendarEntries.filter(
      (calendarEntry) => {
        return (
          (!target || calendarEntry.id !== target.id) &&
          (!origin || calendarEntry.id !== origin.id)
        );
      }
    );
  }

  function _removeMealEntriesForChangeRequest(
    username: string,
    entry: MealEntry
  ) {
    const mealEntries = getMealEntries(username);
    state.mealEntriesByUsername[username] = mealEntries.filter((mealEntry) => {
      return mealEntry.id !== entry.id;
    });
  }

  function _removeCalendarEntryChangeRequest(
    origin: CalendarEntry | null,
    target: CalendarEntry | null
  ) {
    state.changeRequests =
      state.changeRequests?.filter((changeRequest) => {
        return (
          changeRequest.type === "calendar-entry-change-request-complete" &&
          (!changeRequest.origin ||
            !origin ||
            changeRequest.origin.id !== origin.id) &&
          (!changeRequest.target ||
            !target ||
            changeRequest.target.id !== target.id)
        );
      }) || null;
  }

  function _removeMealEntryChangeRequest(entry: MealEntry) {
    state.changeRequests =
      state.changeRequests?.filter((changeRequest) => {
        return (
          changeRequest.type !== "meal-entry-change-request-complete" ||
          changeRequest.entry.id !== entry.id
        );
      }) || null;
  }

  async function cancelChangeRequest(
    username: string,
    changeRequestId: string
  ) {
    const calendarEventLog = await changeRequestWsClient.cancelChangeRequest(
      changeRequestId
    );
    if (
      calendarEventLog.event.type === "cancel-calendar-entry-change-request"
    ) {
      const { target, origin, restoredCalendarEntry } = calendarEventLog.event;

      _removeCalendarEntriesForChangeRequest(username, origin, target);
      if (restoredCalendarEntry) {
        _insertCalendarEntryInState(username, restoredCalendarEntry);
      }
      dispatchCalendarEntryUpdate(username);

      _removeCalendarEntryChangeRequest(origin, target);
      dispatchChangeRequestsUpdate();
    } else if (
      calendarEventLog.event.type === "cancel-meal-entry-change-request"
    ) {
      const { entry } = calendarEventLog.event;
      _removeMealEntriesForChangeRequest(username, entry);
      dispatchMealEntryUpdate(username);
      _removeMealEntryChangeRequest(entry);
      dispatchChangeRequestsUpdate();
    }
  }

  function _getChangeRequestVersion(changeRequestId: string) {
    const changeRequest =
      state.changeRequests &&
      state.changeRequests.find(
        (changeRequest) => changeRequest.id === changeRequestId
      );
    if (!changeRequest) {
      throw new Error(
        `Unable to find changeRequest with ID ${changeRequestId}`
      );
    } else {
      return changeRequest.version;
    }
  }

  async function rejectChangeRequest(
    username: string,
    changeRequestId: string
  ) {
    const changeRequestVersion = _getChangeRequestVersion(changeRequestId);
    const calendarEventLog = await changeRequestWsClient.rejectChangeRequest(
      changeRequestId,
      changeRequestVersion
    );

    if (
      calendarEventLog.event.type === "reject-calendar-entry-change-request"
    ) {
      const { target, origin, restoredCalendarEntry } = calendarEventLog.event;

      _removeCalendarEntriesForChangeRequest(username, origin, target);
      if (restoredCalendarEntry) {
        _insertCalendarEntryInState(username, restoredCalendarEntry);
      }
      dispatchCalendarEntryUpdate(username);

      _removeCalendarEntryChangeRequest(origin, target);
      dispatchChangeRequestsUpdate();
    } else if (
      calendarEventLog.event.type === "reject-meal-entry-change-request"
    ) {
      const { entry } = calendarEventLog.event;
      _removeMealEntriesForChangeRequest(username, entry);
      dispatchMealEntryUpdate(username);
      _removeMealEntryChangeRequest(entry);
      dispatchChangeRequestsUpdate();
    }
  }

  async function acceptChangeRequest(
    username: string,
    changeRequestId: string
  ) {
    const changeRequestVersion = _getChangeRequestVersion(changeRequestId);
    const calendarEventLog = await changeRequestWsClient.acceptChangeRequest(
      changeRequestId,
      changeRequestVersion
    );
    if (
      calendarEventLog.event.type === "accept-calendar-entry-change-request"
    ) {
      const { target, origin, resultingCalendarEntry } = calendarEventLog.event;

      _removeCalendarEntriesForChangeRequest(username, origin, target);
      if (resultingCalendarEntry) {
        _insertCalendarEntryInState(username, resultingCalendarEntry);
      }
      dispatchCalendarEntryUpdate(username);

      _removeCalendarEntryChangeRequest(origin, target);
      dispatchChangeRequestsUpdate();
    } else if (
      calendarEventLog.event.type === "accept-meal-entry-change-request"
    ) {
      const { entry } = calendarEventLog.event;
      state.mealEntriesByUsername[username] = getMealEntries(username).map(
        (mealEntry) => {
          if (mealEntry.id === entry.id) {
            return { ...mealEntry, status: { code: "ok" } };
          } else {
            return mealEntry;
          }
        }
      );
      dispatchMealEntryUpdate(username);
      _removeMealEntryChangeRequest(entry);
      dispatchChangeRequestsUpdate();
    }
  }

  return {
    searchCalendarEntries,
    subscribeForCalendarEntries,
    getCalendarEntries,
    createCalendarEntry,
    updateCalendarEntry,
    deleteCalendarEntry,
    searchMealEntries,
    subscribeForMealEntries,
    getMealEntries,
    createMealEntry,
    deleteMealEntry,
    subscribeForChangeRequests,
    get changeRequests() {
      return state.changeRequests;
    },
    isLoggedUserAllowedToManageChangeRequests,
    refreshChangeRequestsIfAllowed,
    cancelChangeRequest,
    rejectChangeRequest,
    acceptChangeRequest,
  };
}
