// @flow
import { rawAxios } from "./_axiosInstances";

export const authWsClient = {
  logIn(userCredentials: {|
    username: string,
    password: string,
  |}): Promise<InitialStateType> {
    return rawAxios
      .post("/ws/authentication/login", userCredentials)
      .then((res) => {
        return res.data;
      });
  },

  logOut(): Promise<void> {
    return rawAxios.post("/ws/authentication/logOut").then(() => {
      return Promise.resolve();
    });
  },
};
