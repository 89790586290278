// @flow
import type { I18nEntryType } from "../../utils/i18n";

export const emailAddressesValidationRules = {
  emailAddresses(v: ?$ReadOnlyArray<string>): ?$ReadOnlyArray<I18nEntryType> {
    if (!v || !Array.isArray(v) || v.length === 0) {
      return [
        "app.manageUsers.view.createUser.error.form.field.mustHaveAtLeastOneEmail",
      ];
    } else if (
      v &&
      Array.isArray(v) &&
      v.some(
        (email) =>
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
          )
      )
    ) {
      return [
        "app.manageUsers.view.createUser.error.form.field.emailAddressesMustBeValid",
      ];
    }
  },
};

export const changePasswordValidationRules = {
  newPasswordConfirmation(
    v: ?string,
    vals: { [string]: any }
  ): ?$ReadOnlyArray<I18nEntryType> {
    if (v !== vals.newPassword) {
      return ["error.form.field.passwordsDontMatch"];
    }
  },
};
