// @flow
import * as React from "react";
import {
  Button,
  Callout,
  Card,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import logo from "./logo-small.png";
import logoBright from "./logo-small-bright.png";
import "./LoginPage.css";
import { authWsClient } from "../ws-client/authWsClient";
import { useLocation } from "react-router";
import { isRedirectedFrom401 } from "../utils/redirectToLoginPage";
import { useI18n } from "../components/format-entry/useI18n";
import { FormatEntry } from "../components/format-entry/FormatEntry";

type LoginPagePropsType = {|
  isDarkMode: boolean,
  handleInitialState: (InitialStateType) => void,
|};

export function LoginPage(props: LoginPagePropsType): React.Node {
  const { isDarkMode, handleInitialState } = props;
  const { search } = useLocation();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    global: null,
    username: null,
    password: null,
  });
  const { formatEntry } = useI18n();

  return (
    <div className="loginPage">
      <div className="logoContainer">
        <img
          src={isDarkMode ? logoBright : logo}
          alt={formatEntry("loginPage.logo.alt")}
          className="logo"
        />
      </div>
      <Card className="card">
        {isRedirectedFrom401(new URLSearchParams(search)) && (
          <Callout intent={Intent.DANGER}>
            <FormatEntry i18nEntry="loginPage.message.expiredSession" />
          </Callout>
        )}
        <div className="cardContent">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsLoading(true);
              authWsClient
                .logIn({ username, password })
                .then((initialState) => {
                  handleInitialState(initialState);
                  setIsLoading(false);
                })
                .catch((err) => {
                  setIsLoading(false);
                  if (err.response?.status === 400) {
                    const { code } = err.response.data;
                    if (code === "USER_NOT_FOUND_BY_USERNAME") {
                      setErrors({
                        global: null,
                        username: (
                          <FormatEntry i18nEntry="loginPage.error.userDoesNotExist" />
                        ),
                        password: null,
                      });
                    } else if (code === "USER_PASSWORD_DOES_NOT_MATCH") {
                      setErrors({
                        global: null,
                        username: null,
                        password: (
                          <FormatEntry i18nEntry="loginPage.error.invalidPassword" />
                        ),
                      });
                    } else {
                      setErrors({
                        global: (
                          <FormatEntry i18nEntry="error.unexpectedError" />
                        ),
                        username: null,
                        password: null,
                      });
                    }
                  } else {
                    setErrors({
                      global: <FormatEntry i18nEntry="error.unexpectedError" />,
                      username: null,
                      password: null,
                    });
                  }
                });
            }}
          >
            <FormGroup
              label={
                <strong>
                  <FormatEntry i18nEntry="model.user.username.label" />
                </strong>
              }
              labelFor="username"
              className="formGroup"
              intent={errors.username ? Intent.DANGER : undefined}
              helperText={errors.username}
            >
              <InputGroup
                id="username"
                name="username"
                autoComplete="username"
                autoFocus
                large
                required
                value={username}
                intent={errors.username ? Intent.DANGER : undefined}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                spellCheck={false}
                autoCapitalize="none"
              />
            </FormGroup>
            <FormGroup
              label={
                <strong>
                  <FormatEntry i18nEntry="model.user.password.label" />
                </strong>
              }
              labelFor="password"
              className="formGroup"
              intent={errors.password ? Intent.DANGER : undefined}
              helperText={errors.password}
            >
              <InputGroup
                id="password"
                type="password"
                name="password"
                autoComplete="password"
                large
                required
                intent={errors.password ? Intent.DANGER : undefined}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormGroup>
            <Button
              type="submit"
              intent={Intent.PRIMARY}
              loading={isLoading}
              large
              fill
            >
              <FormatEntry i18nEntry="loginPage.action.login" />
            </Button>
          </form>
        </div>
      </Card>
    </div>
  );
}
