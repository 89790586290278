// @flow
import * as React from "react";
import clsx from "clsx";
import {
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverPosition,
} from "@blueprintjs/core";
import "./TopBar.css";
import { Link, useRouteMatch } from "react-router-dom";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import essentielIcon from "./icon.png";
import { FormatEntry } from "../format-entry/FormatEntry";
import { getManageSystemParametersUrl } from "../../apps/manage-system-parameters/routes";
import { getManageUsersUrl } from "../../apps/manage-users/routes";
import { getEditProfileUrl } from "../../apps/edit-profile/routes";
import { useAppEnv } from "../../hooks/useAppEnv";

type TopBarPropsType = {|
  logOut: () => void,
  isDarkMode: boolean,
  setIsDarkMode: (boolean) => void,
|};

export function TopBar(props: TopBarPropsType): React.Node {
  const { logOut } = props;
  const loggedUser = useLoggedUser();
  const appEnv = useAppEnv();
  return (
    <header>
      <div className={clsx("topBar", appEnv)}>
        <div className="topBarContent">
          <nav className="navigation ">
            <Link className="homeLink" to="/">
              <img src={essentielIcon} alt="icone essentiel" className="logo" />
              <span className="text">
                <FormatEntry i18nEntry="component.topBar.title" />
                {appEnv === "development" && " (dev)"}
                {appEnv === "test" && " (test)"}
              </span>
            </Link>
          </nav>
          {loggedUser.role === "superAdmin" && (
            <nav className="navigation autoScrollOnOverflowX">
              <NavigationLink to="/presences" icon="calendar">
                <FormatEntry i18nEntry="component.topBar.links.schedule" />
              </NavigationLink>
              <NavigationLink to={getManageUsersUrl()} icon="people">
                <FormatEntry i18nEntry="component.topBar.links.users" />
              </NavigationLink>
              <NavigationLink to={getManageSystemParametersUrl()} icon="cog">
                <FormatEntry i18nEntry="component.topBar.links.parameters" />
              </NavigationLink>
            </nav>
          )}
          <nav className="navigation">
            <Popover
              position={PopoverPosition.BOTTOM_RIGHT}
              className="fullHeight"
              targetClassName="fullHeight"
              minimal
              content={
                <Menu className="profile-menu" large>
                  <Link to={getEditProfileUrl()}>
                    <MenuItem
                      icon="edit"
                      text={
                        <FormatEntry i18nEntry="component.topBar.action.editProfile" />
                      }
                    />
                  </Link>
                  <MenuDivider />
                  <MenuItem
                    icon="log-out"
                    text={<FormatEntry i18nEntry="common.logOut" />}
                    onClick={() => {
                      logOut();
                    }}
                  />
                </Menu>
              }
            >
              <span className="fullHeight bp3-dark">
                <Link
                  to={getEditProfileUrl()}
                  className="userDropdown"
                  onClick={(e) => e.preventDefault()}
                >
                  <Icon icon="user" />
                  <span
                    className={`userFullName ${
                      loggedUser.role === "superAdmin" ? "compact" : ""
                    }`}
                  >{`${loggedUser.firstName} ${loggedUser.lastName}`}</span>
                  <Icon icon="caret-down" />
                </Link>
              </span>
            </Popover>
          </nav>
        </div>
      </div>
    </header>
  );
}

function NavigationLink(props: {|
  to: string,
  icon: string,
  children?: React.Node,
|}) {
  const { to, icon, children } = props;
  const match = useRouteMatch(to);
  const innerNodes = (
    <>
      <Icon icon={icon} iconSize={18} />
      {!!children && <span className="text">{children}</span>}
    </>
  );
  if (match) {
    return <div className="navigationLink active">{innerNodes}</div>;
  }
  return (
    <Link to={to} className="navigationLink">
      {innerNodes}
    </Link>
  );
}
