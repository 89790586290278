// @flow
import * as React from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useLoggedUser } from "../hooks/useLoggedUser";
import {
  Button,
  Callout,
  Classes,
  Dialog,
  H4,
  Intent,
} from "@blueprintjs/core";

type AnnouncementWrapperProps = {|
  children: React.Node,
|};

export function AnnouncementWrapper(
  props: AnnouncementWrapperProps
): React.Node {
  const { children } = props;
  const [isAnnouncementExpired] = React.useState(
    () => new Date() >= new Date("2022-07-31")
  );
  const loggedUser = useLoggedUser();
  const [isNewValidationSystemAccepted, setIsValidationSystemAccepted] =
    useLocalStorage(
      "is-new-validation-system-accepted",
      (serializedVal) => serializedVal === "true",
      (val) => String(val),
      false
    );

  if (
    loggedUser.role !== "resident" ||
    isAnnouncementExpired ||
    isNewValidationSystemAccepted
  ) {
    return <>{children}</>;
  }

  const { typeOfCare } = loggedUser;
  const isRs = typeOfCare.type === "respite-stop";
  return (
    <>
      <Dialog isOpen isCloseButtonShown={false} style={{ zIndex: 200 }}>
        <div className={Classes.DIALOG_BODY}>
          <Callout
            intent={Intent.PRIMARY}
            title={`Informations importantes concernant l'encodage des ${
              isRs ? "présences" : "absences"
            }`}
          />
          <H4 className="marginTop20px">Flexibilité d'encodage</H4>
          <p style={{ textAlign: "justify" }}>
            Vous disposez désormais de plus de flexibilité pour les encodages de
            dernière minute. Il est maintenant possible{" "}
            <strong style={{ textDecoration: "underline" }}>
              {isRs
                ? "de supprimer ou réduire une présence"
                : "d'ajouter ou étendre une absence"}{" "}
              sans restriction
            </strong>{" "}
            dans le calendrier. Pour la bonne organisation de L'Essentiel, nous
            vous demandons toutefois de continuer autant que possible à réaliser
            vos encodages au moins un mois à l'avance.
          </p>
          <H4 className="marginTop20px">
            Validation des présences de dernière minute
          </H4>
          <p style={{ textAlign: "justify" }}>
            Si, dans la plage des 14 prochains jours, vous{" "}
            <strong style={{ textDecoration: "underline" }}>
              {isRs
                ? "ajoutez une nouvelle présence ou étendez une présence existante"
                : "supprimez ou réduisez une absence existante"}
            </strong>
            , ce changement apparaîtra en attente et devra faire l'objet d'une
            approbation de la part des responsables "éduc" et "infi" pour raison
            d’impératifs de service.{" "}
            <strong style={{ textDecoration: "underline" }}>
              Il n'est plus nécessaire d'envoyer un e-mail pour faire ce genre
              de demande
            </strong>
            , les responsables en seront automatiquement avertis par le système.
            Vous recevrez un e-mail dès que la demande aura été acceptée ou
            refusée.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setIsValidationSystemAccepted(true)}>
              J'ai lu et j'ai compris le nouveau système en place
            </Button>
          </div>
        </div>
      </Dialog>
      {children}
    </>
  );
}

AnnouncementWrapper.displayName = "AnnouncementWrapper";
