// @flow
import * as React from "react";
import { FormatEntry } from "../format-entry/FormatEntry";
import { FormGroup } from "@blueprintjs/core";
import type { I18nEntryType } from "../../utils/i18n";

type FormElementPropsType = {|
  label?: I18nEntryType,
  labelSideNode?: I18nEntryType,
  labelFor?: string,
  className?: string,
  intent?: BlueprintIntentType,
  helperText?: React.Node,
  inline?: boolean,
  noMarginBottom?: boolean,
  disabled?: boolean,
  children: React.Node,
|};

export function FormElement(props: FormElementPropsType): React.Node {
  const {
    label,
    labelSideNode,
    labelFor,
    className,
    intent,
    helperText,
    inline,
    noMarginBottom,
    disabled,
    children,
  } = props;
  const style = {};
  if (inline && !helperText) {
    style.alignItems = "center";
  }
  if (noMarginBottom) {
    style.marginBottom = 0;
  }
  return (
    <FormGroup
      label={
        <strong>
          {label && <FormatEntry i18nEntry={label} />}
          {labelSideNode && (
            <>
              {" "}
              (<FormatEntry i18nEntry={labelSideNode} />)
            </>
          )}
        </strong>
      }
      labelFor={labelFor}
      intent={intent}
      helperText={helperText}
      inline={inline}
      disabled={disabled}
      className={className}
      style={style}
    >
      {children}
    </FormGroup>
  );
}
