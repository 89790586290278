// @flow
import { rawAxios } from "./_axiosInstances";

export const initialStateWsClient = {
  getInitialState(): Promise<InitialStateType> {
    // Considering the WS usage, do NOT use axiosRedirectOn401
    return rawAxios.get("/ws/initial-state").then((res) => {
      return res.data;
    });
  },
};
