// @flow
import { axiosRedirectOn401 } from "./_axiosInstances";

export const profileWsClient = {
  updateEmailAddresses(emailAddresses: $ReadOnlyArray<string>): Promise<User> {
    return axiosRedirectOn401
      .put("/ws/profile/emailAddresses", { emailAddresses })
      .then((res) => {
        return res.data;
      });
  },
  updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    return axiosRedirectOn401
      .put("/ws/profile/password", { currentPassword, newPassword })
      .then(() => {
        return Promise.resolve();
      });
  },
};
