// @flow
import "./polyfills";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { FocusStyleManager } from "@blueprintjs/core";
import flatten from "flat";
import { store } from "./redux/store";
import frenchMessages from "./i18n/fr.json";
import { App } from "./apps/App";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "./blueprint-overwrites.css";
import "./index.css";

FocusStyleManager.onlyShowFocusOnTabs();

const locale = "fr";
const messages = flatten(frenchMessages);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          <App />
        </IntlProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
