// @flow
import type {
  ActionsType,
  LoggedUserStateType,
  UpdateLoggedUserActionType,
} from "../types";

export function loggedUserReducer(
  state: LoggedUserStateType = null,
  action: ActionsType
): LoggedUserStateType {
  if (action.type === "update-logged-user") {
    const { user } = action.payload;
    return user;
  }
  return state;
}

export function createUpdateLoggedUserAction(
  user: User
): UpdateLoggedUserActionType {
  return {
    type: "update-logged-user",
    payload: { user },
  };
}
