// @flow
import * as React from "react";

export function useLocalStorage<T>(
  localStorageKey: string,
  parse: (string | null) => T,
  format: (T) => string,
  init?: T
): [T, (((T) => T) | T) => void] {
  const [val, setVal] = React.useState<T>(() => {
    const value = getParsedLocalStorageItem(localStorageKey, parse);
    if (value !== undefined) {
      return value;
    } else if (init) {
      return init;
    } else {
      return parse(null);
    }
  });

  React.useEffect(() => {
    setLocalStorageItem(localStorageKey, val, format);
  }, [localStorageKey, val, format]);

  return [val, setVal];
}

function getParsedLocalStorageItem<T>(
  localStorageKey: string,
  parse: (string | null) => T
): T | void {
  const localStorageValue = localStorage.getItem(localStorageKey);
  if (localStorageValue === undefined || localStorageValue === null) {
    return undefined;
  } else {
    return parse(localStorageValue);
  }
}

function setLocalStorageItem<T>(
  localStorageKey: string,
  value: T,
  format: (T) => string
) {
  localStorage.setItem(localStorageKey, format(value));
}
