import type { ScheduleManager } from "../calendar-entry-provider-types";

export function createInertScheduleManager(): ScheduleManager {
  return {
    subscribeForCalendarEntries() {
      return () => {};
    },
    getCalendarEntries() {
      return [];
    },
    async searchCalendarEntries() {},
    async createCalendarEntry() {},
    async updateCalendarEntry() {},
    async deleteCalendarEntry() {},
    subscribeForMealEntries() {
      return () => {};
    },
    getMealEntries() {
      return [];
    },
    async searchMealEntries() {},
    async createMealEntry() {},
    async deleteMealEntry() {},
    subscribeForChangeRequests() {
      return () => {};
    },
    changeRequests: null,
    isLoggedUserAllowedToManageChangeRequests: false,
    async refreshChangeRequestsIfAllowed() {},
    async cancelChangeRequest() {},
    async rejectChangeRequest() {},
    async acceptChangeRequest() {},
  };
}
