// @flow
import type { Store } from "redux";
import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnly";
import { loggedUserReducer } from "./model/loggedUser";
import { simpleUsersReducer } from "./model/simpleUsers";
import { appEnvReducer } from "./model/appEnv";
import type { ActionsType, StateType } from "./types";
import { calendarEntryDeadlinesReducer } from "./model/calendarEntryDeadlines";

const composedEnhancers = composeWithDevTools();

export const store: Store<StateType, ActionsType> = createStore(
  combineReducers({
    loggedUser: loggedUserReducer,
    simpleUsers: simpleUsersReducer,
    appEnv: appEnvReducer,
    calendarEntryDeadlines: calendarEntryDeadlinesReducer,
  }),
  composedEnhancers
);
