// @flow
import axios from "axios";
import { redirectToLoginPage } from "../utils/redirectToLoginPage";

export const rawAxios = (axios.create(): Object);

// Feature: we want to redirect to the login page if the user is no longer logged in
export const axiosRedirectOn401 = (axios.create(): Object);
axiosRedirectOn401.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      redirectToLoginPage({ logOutSource: "disconnectedFrom401" });
    }
    return Promise.reject(err);
  }
);
