// @flow
import { useSelector } from "react-redux";
import type { StateType } from "../redux/types";

export function useLoggedUser(): User {
  const loggedUser = useSelector<StateType, User | null>(
    (state) => state.loggedUser
  );
  if (!loggedUser) {
    throw new Error("useLoggedUser may be called only when a user is logged");
  }
  return loggedUser;
}
