// @flow
import * as React from "react";
import { useDispatch } from "react-redux";
import { Form } from "../../components/form/Form";
import {
  changePasswordValidationRules,
  emailAddressesValidationRules,
} from "./validation-rules";
import { FormElementTextMulti } from "../../components/form-element/FormElementTextMulti";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import { FormElementPassword } from "../../components/form-element/FormElementPassword";
import { Button, Callout, H3, Intent } from "@blueprintjs/core";
import { FormatEntry } from "../../components/format-entry/FormatEntry";
import { profileWsClient } from "../../ws-client/profileWsClient";
import { createUpdateLoggedUserAction } from "../../redux/model/loggedUser";

type EditProfilePropsType = {||};

export function EditProfile(props: EditProfilePropsType): React.Node {
  const { emailAddresses } = useLoggedUser();
  const dispatch = useDispatch();
  const [wasEmailAddressUpdateSuccessful, setWasEmailAddressUpdateSuccessful] =
    React.useState(null);
  const [wasPasswordUpdateSuccessful, setWasPasswordUpdateSuccessful] =
    React.useState(null);
  return (
    <div className="view-content">
      <div className="centered-content">
        <Form
          initialValues={{ emailAddresses }}
          validationRules={emailAddressesValidationRules}
          onSubmit={(values) => {
            const { emailAddresses } = values;
            setWasEmailAddressUpdateSuccessful(null);
            return profileWsClient
              .updateEmailAddresses(emailAddresses)
              .then((user) => {
                setWasEmailAddressUpdateSuccessful(true);
                dispatch(createUpdateLoggedUserAction(user));
              });
          }}
          disableAutoComplete
        >
          {({ values, errors, getOnChange, getIntent }) => {
            const { emailAddresses } = values;
            return (
              <>
                <H3>
                  <FormatEntry i18nEntry="model.user.emailAddresses.label" />
                </H3>
                {wasEmailAddressUpdateSuccessful && (
                  <Callout
                    intent={Intent.SUCCESS}
                    title={
                      <FormatEntry i18nEntry="app.editProfile.editEmailAddressesSuccess" />
                    }
                  />
                )}
                <FormElementTextMulti
                  intent={getIntent("emailAddresses")}
                  helperText={errors.emailAddresses}
                  value={emailAddresses}
                  onChange={getOnChange("emailAddresses")}
                />
                <Button type="submit" intent={Intent.PRIMARY}>
                  <FormatEntry i18nEntry="action.validate" />
                </Button>
              </>
            );
          }}
        </Form>
        <Form
          initialValues={{ emailAddresses }}
          validationRules={changePasswordValidationRules}
          onSubmit={(values) => {
            const { currentPassword, newPassword } = values;
            setWasPasswordUpdateSuccessful(null);
            return profileWsClient
              .updatePassword(currentPassword, newPassword)
              .then(() => {
                setWasPasswordUpdateSuccessful(true);
              });
          }}
          disableAutoComplete
        >
          {({ values, errors, getOnChange, getIntent }) => {
            const { currentPassword, newPassword, newPasswordConfirmation } =
              values;
            return (
              <>
                <H3>
                  <FormatEntry i18nEntry="model.user.password.label" />
                </H3>
                {wasPasswordUpdateSuccessful && (
                  <Callout
                    intent={Intent.SUCCESS}
                    title={
                      <FormatEntry i18nEntry="app.editProfile.editPasswordSuccess" />
                    }
                  />
                )}
                <FormElementPassword
                  label="app.editProfile.field.currentPassword.label"
                  intent={getIntent("currentPassword")}
                  helperText={errors.currentPassword}
                  value={currentPassword}
                  onChange={getOnChange("currentPassword")}
                />
                <FormElementPassword
                  label="app.editProfile.field.newPassword.label"
                  intent={getIntent("newPassword")}
                  helperText={errors.newPassword}
                  value={newPassword}
                  onChange={getOnChange("newPassword")}
                />
                <FormElementPassword
                  label="app.editProfile.field.newPasswordConfirmation.label"
                  intent={getIntent("newPasswordConfirmation")}
                  helperText={errors.newPasswordConfirmation}
                  value={newPasswordConfirmation}
                  onChange={getOnChange("newPasswordConfirmation")}
                />
                <Button type="submit" intent={Intent.PRIMARY}>
                  <FormatEntry i18nEntry="action.validate" />
                </Button>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
}
