// @flow
import type {
  ActionsType,
  DeleteSimpleUserActionType,
  RefreshAllSimpleUsersActionType,
  SimpleUsersStateType,
  UpdateSimpleUserActionType,
} from "../types";

export function simpleUsersReducer(
  state: SimpleUsersStateType = {},
  action: ActionsType
): SimpleUsersStateType {
  if (action.type === "update-simple-user") {
    const { simpleUser } = action.payload;
    return { ...state, [simpleUser.username]: simpleUser };
  } else if (action.type === "delete-simple-user") {
    const { username } = action.payload;
    const newState = { ...state };
    delete newState[username];
    return newState;
  } else if (action.type === "refresh-all-simple-users") {
    const { simpleUsers } = action.payload;
    const newState = {};
    for (let simpleUser of simpleUsers) {
      newState[simpleUser.username] = simpleUser;
    }
    return newState;
  }
  return state;
}

export function createUpdateSimpleUserAction(
  simpleUser: SimpleUser
): UpdateSimpleUserActionType {
  return {
    type: "update-simple-user",
    payload: { simpleUser },
  };
}

export function createDeleteSimpleUserAction(
  username: string
): DeleteSimpleUserActionType {
  return {
    type: "delete-simple-user",
    payload: { username },
  };
}

export function createRefreshAllSimpleUsersAction(
  simpleUsers: $ReadOnlyArray<SimpleUser>
): RefreshAllSimpleUsersActionType {
  return {
    type: "refresh-all-simple-users",
    payload: { simpleUsers },
  };
}
