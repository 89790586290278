// @flow
import type {
  ActionsType,
  CalendarEntryDeadlinesStateType,
  UpdateCalendarEntryDeadlinesActionType,
} from "../types";

export function calendarEntryDeadlinesReducer(
  state: CalendarEntryDeadlinesStateType = null,
  action: ActionsType
): CalendarEntryDeadlinesStateType {
  if (action.type === "update-calendar-entry-deadlines") {
    const { calendarEntryDeadlines } = action.payload;
    return calendarEntryDeadlines;
  }
  return state;
}

export function createUpdateCalendarEntryDeadlinesAction(
  calendarEntryDeadlines: CalendarEntryDeadlines
): UpdateCalendarEntryDeadlinesActionType {
  return {
    type: "update-calendar-entry-deadlines",
    payload: { calendarEntryDeadlines },
  };
}
