// @flow
import { useIntl } from "react-intl";
import { normalizeI18nEntry } from "../../utils/i18n";
import type { I18nEntryType } from "../../utils/i18n";

type UseI18nReturnType = {|
  formatEntry: (I18nEntryType) => string,
  formatList: (
    listElements: $ReadOnlyArray<string>,
    type?: "conjunction" | "disjunction" | "unit"
  ) => string,
|};

export function useI18n(): UseI18nReturnType {
  const { formatMessage, formatList } = useIntl();
  return {
    formatEntry(i18nEntry: I18nEntryType) {
      const normalizedI18nEntry = normalizeI18nEntry(i18nEntry);
      return formatMessage(
        { id: normalizedI18nEntry.key },
        normalizedI18nEntry.parameters
      );
    },
    formatList(
      listElements: $ReadOnlyArray<string>,
      type?: "conjunction" | "disjunction" | "unit"
    ) {
      return formatList(listElements, { type });
    },
  };
}
