// @flow
import * as React from "react";
import { Unauthorized } from "./pages/Unauthorized";
import { useLoggedUser } from "../hooks/useLoggedUser";
import { UnexpectedError } from "./pages/UnexpectedError";

type PageErrorBoundaryPropsType = {|
  children: React.Node,
|};

type CaughtError =
  | {| type: "UnauthorizedError" |}
  | {| type: "UnexpectedError", error: Error |};

type PageErrorBoundaryStateType = {|
  error: CaughtError | null,
|};

export class AppErrorBoundary extends React.Component<
  PageErrorBoundaryPropsType,
  PageErrorBoundaryStateType
> {
  state: PageErrorBoundaryStateType = { error: null };

  static getDerivedStateFromError(error: Error): PageErrorBoundaryStateType {
    if (error.name === "UnauthorizedError") {
      return { error: { type: "UnauthorizedError" } };
    } else {
      return { error: { type: "UnexpectedError", error } };
    }
  }

  render(): React.Node {
    const { children } = this.props;
    const { error } = this.state;
    if (error && error.type === "UnauthorizedError") {
      return <Unauthorized />;
    } else if (error && error.type === "UnexpectedError") {
      return <UnexpectedError error={error.error} />;
    } else {
      return children;
    }
  }
}

export function useEnsureLoggedUserHasOneOfRoles(
  ...roles: $ReadOnlyArray<UserRole>
) {
  const loggedUser = useLoggedUser();
  if (roles.includes(loggedUser.role)) {
    return;
  }
  const err = new Error(
    `The logged user must have one of the following roles: ${
      roles.length === 0 ? "-no roles provided-" : roles.toString()
    }`
  );
  err.name = "UnauthorizedError";
  throw err;
}
