// @flow
import * as React from "react";
import { Button } from "@blueprintjs/core";
import { FormatEntry } from "../../components/format-entry/FormatEntry";
import { PageTemplate } from "./page-template/PageTemplate";

type UserNotAllowedToConnectErrorPropsType = {|
  logOut: () => void,
|};

export function UserNotAllowedToConnectError(
  props: UserNotAllowedToConnectErrorPropsType
): React.Node {
  const { logOut } = props;
  return (
    <PageTemplate
      iconName="blocked-person"
      title={
        <FormatEntry i18nEntry="page.userNotAllowedToConnectError.title" />
      }
      fullScreen
    >
      <p>
        <FormatEntry i18nEntry="page.userNotAllowedToConnectError.description.p1" />
      </p>
      <p>
        <FormatEntry i18nEntry="page.userNotAllowedToConnectError.description.p2_dangerouslySetInnerHtml" />
      </p>
      <p>
        <Button
          onClick={() => {
            logOut();
          }}
        >
          <FormatEntry i18nEntry="common.logOut" />
        </Button>
      </p>
    </PageTemplate>
  );
}
