// @flow
import * as React from "react";
import { Button } from "@blueprintjs/core";
import { FormatEntry } from "../../components/format-entry/FormatEntry";
import { PageTemplate } from "./page-template/PageTemplate";

type InactiveUserErrorPropsType = {|
  logOut: () => void,
|};

export function InactiveUserError(
  props: InactiveUserErrorPropsType
): React.Node {
  const { logOut } = props;
  return (
    <PageTemplate
      iconName="blocked-person"
      title={<FormatEntry i18nEntry="page.inactiveUserError.title" />}
      fullScreen
    >
      <p>
        <FormatEntry i18nEntry="page.inactiveUserError.description" />
      </p>
      <p>
        <Button
          onClick={() => {
            logOut();
          }}
        >
          <FormatEntry i18nEntry="common.logOut" />
        </Button>
      </p>
    </PageTemplate>
  );
}
