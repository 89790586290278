// @flow
import { axiosRedirectOn401 } from "./_axiosInstances";

export const mealEntryWSClient = {
  createMealEntry(
    username: string,
    mealEntryCreate: Object
  ): Promise<CalendarEventLog> {
    return axiosRedirectOn401
      .post(`/ws/mealEntry/${username}`, mealEntryCreate)
      .then((res) => res.data);
  },

  searchMealEntries(
    lowerBoundDate: DateString,
    upperBoundDate: DateString,
    usernames: $ReadOnlyArray<string>
  ): Promise<$ReadOnlyArray<CalendarEntry>> {
    const body = {
      lowerBoundDate,
      upperBoundDate,
      usernames,
    };
    return axiosRedirectOn401.post(`/ws/mealEntry/search`, body).then((res) => {
      return res.data;
    });
  },

  deleteMealEntry(mealEntryId: string): Promise<CalendarEventLog | void> {
    return axiosRedirectOn401
      .delete(`/ws/mealEntry/${mealEntryId}`)
      .then((res) => res.data);
  },
};
