// @flow
import * as React from "react";
import type { I18nEntryType } from "../../utils/i18n";
import { FormElement } from "./FormElement";
import { InputGroup } from "@blueprintjs/core";

type FormElementPasswordPropsType = {|
  label: I18nEntryType,
  labelSideNode?: I18nEntryType,
  intent?: BlueprintIntentType,
  helperText?: React.Node,
  autoComplete?: string,
  value?: string | null,
  onChange: (string | null) => void,
  readOnly?: boolean,
|};

export function FormElementPassword(
  props: FormElementPasswordPropsType
): React.Node {
  const {
    label,
    labelSideNode,
    intent,
    helperText,
    autoComplete,
    value,
    onChange,
    readOnly,
  } = props;
  return (
    <FormElement
      label={label}
      labelSideNode={labelSideNode}
      intent={intent}
      helperText={helperText}
    >
      {readOnly ? (
        "••••••••••••"
      ) : (
        <InputGroup
          type="password"
          value={value || ""}
          onChange={(e) => onChange(e.target.value || null)}
          intent={intent}
          autoComplete={autoComplete}
        />
      )}
    </FormElement>
  );
}
