// @flow
import * as React from "react";
import type { I18nEntryType } from "../../utils/i18n";
import { FormElement } from "./FormElement";
import { Button, InputGroup } from "@blueprintjs/core";

type FormElementTextMultiPropsType = {|
  label?: I18nEntryType,
  intent?: BlueprintIntentType,
  helperText?: React.Node,
  value?: $ReadOnlyArray<string | null> | null,
  onChange: ($ReadOnlyArray<string | null>) => void,
  readOnly?: boolean,
|};

export function FormElementTextMulti(
  props: FormElementTextMultiPropsType
): React.Node {
  const { label, intent, helperText, value, onChange, readOnly } = props;
  const definedValue = value && value.length > 0 ? value : [null];
  return (
    <FormElement label={label} intent={intent} helperText={helperText}>
      {readOnly && definedValue.filter((val) => !!val).join(", ")}
      {!readOnly &&
        definedValue.map((subValue, i) => {
          function handleChange(e) {
            const value = e.target.value;
            return onChange([
              ...definedValue.slice(0, i),
              value,
              ...definedValue.slice(i + 1, definedValue.length),
            ]);
          }

          function remove() {
            onChange([
              ...definedValue.slice(0, i),
              ...definedValue.slice(i + 1, definedValue.length),
            ]);
          }

          return (
            <div style={{ display: "flex" }} key={i}>
              <div style={{ flexGrow: 1 }}>
                <InputGroup
                  key={i}
                  value={subValue || ""}
                  onChange={handleChange}
                  autocomplete="off"
                />
              </div>
              {definedValue.length > 1 && (
                <Button icon="minus" onClick={remove} />
              )}
            </div>
          );
        })}
      {!readOnly && (
        <Button
          icon="plus"
          onClick={() => {
            onChange([...definedValue, null]);
          }}
        />
      )}
    </FormElement>
  );
}
